import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { PilotageId, RouteChainId, RouteEndpoint, SelectRouteChainRow } from "apina-frontend";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MinutesDurationPipe } from "common";
import { HeadingComponent } from "../../common/heading/heading.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";

@Component({
    templateUrl: './select-route-chain-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HeadingComponent,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MinutesDurationPipe,
        ReactiveFormsModule,
        MatDialogTitle,
        MatDialogContent,
    ],
    host: {
        "class": "!block max-h-[500px] max-w-[800px]"
    }
})
export class SelectRouteChainDialog {

    readonly form = new FormGroup({
        startCode: new FormControl<string>("", {nonNullable: true}),
        endCode: new FormControl<string>("", {nonNullable: true}),
        name: new FormControl<string>("", {nonNullable: true}),
    });

    readonly pilotageId: PilotageId | null = null;

    readonly searchResults = signal<SelectRouteChainRow[] | undefined>(undefined);

    constructor(
        private readonly routeEndpoint: RouteEndpoint,
        @Inject(MAT_DIALOG_DATA) private readonly params: SelectRouteChainParams,
        private readonly dialogRef: MatDialogRef<SelectRouteChainDialog>,
    ) {
        this.form.controls.startCode.reset(params.start);

        this.search();
    }

    search(): void {
        const startCode = this.form.controls.startCode.value;
        const endCode = this.form.controls.endCode.value;
        const name = this.form.controls.name.value;

        this.searchResults.set(undefined);
        this.routeEndpoint.findMatchingRouteChains(startCode, endCode, name, this.pilotageId)
            .subscribe(chains => this.searchResults.set(chains));
    }

    selectRoute(chain: SelectRouteChainRow): void {
        this.dialogRef.close();
        this.params.routeChainSelected(chain.id);
    }
}

export interface SelectRouteChainParams {
    start: string;
    routeChainSelected: RouteChainSelectedCallback;
}

export type RouteChainSelectedCallback = (id: RouteChainId) => void;
