<app-common-dialog-form
    [delegate]="this"
    [dialogTitle]="title"
    saveText="Palauta arvioksi">

    <app-vertical-form>
        <app-datetime-field label="ETA" [control]="form.controls.eta" [required]="true"/>
        <app-schedule-source-field label="Aikataulutiedon lähde" [control]="form.controls.source"/>

        <app-input-row>
            <app-berth-field label="Alkulaituri" [control]="form.controls.startBerthId" [endpointId]="params.route.start.id"/>
            <app-berth-field label="Loppulaituri" [control]="form.controls.endBerthId" [endpointId]="params.route.end.id"/>
        </app-input-row>

    </app-vertical-form>
</app-common-dialog-form>
