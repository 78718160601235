<h3 mat-dialog-title>{{ params.vesselName }}: {{ params.route }}</h3>
<mat-dialog-content style="min-width: 600px">
    @if (rows(); as rows) {
        <table class="default-table">
            <thead>
            <tr>
                <th>Aika</th>
                <th>Vaihe</th>
                <th></th>
                <th>Eta</th>
                <th>Tiedon lähde</th>
                @if (mayAccept()) {
                    <th></th>
                }
            </tr>
            </thead>
            <tbody>
                @for (row of rows; track $index) {
                    <tr>
                        <td>{{ row.time | helsinkiDate }}</td>
                        <td [class]="row.className" [matTooltip]="row.tooltip" matTooltipClass="multiline">{{ row.phase }}</td>
                        <td>{{ row.minutes | duration }}</td>
                        <td>{{ row.eta | helsinkiDate }}</td>
                        <td>{{ row.sourceName }}</td>
                        @if (mayAccept()) {
                            <td class="actions-cell">
                                @if (row.mayApprove) {
                                    <button mat-icon-button (click)="resetTos(row)" matTooltip="Hyväksy palveluehtojen mukaiseksi">
                                        <mat-icon>approval</mat-icon>
                                    </button>
                                }
                            </td>
                        }
                    </tr>
                }
                @if (draftViolation(); as violation) {
                    @if (violation.hasDraftViolation) {
                        <tr>
                            <td></td>
                            <td class="!text-tos-red" colspan="4">Syväystieto puuttuu tai annettu myöhässä</td>
                            <td class="actions-cell">
                                <button mat-icon-button (click)="approveDraftViolation(params.pilotageId)" matTooltip="Hyväksy palveluehtojen mukaiseksi">
                                    <mat-icon>approval</mat-icon>
                                </button>
                            </td>
                        </tr>
                    }
                    @else if (!violation.hasDraftViolation && violation.draftApproval != null) {
                        <tr>
                            <td></td>
                            <td class="!text-tos-green italic" colspan="4" [matTooltip]="approvedDraftViolationTooltip(violation.draftApproval)">Syväystieto annettu</td>
                        </tr>
                    }
                }
                @if (severeTosViolation(); as violation) {
                    <tr>
                        <td></td>
                        <td class="!text-tos-red" colspan="4" [matTooltip]="violationTooltip(violation)" matTooltipClass="multiline">Räikeästi palveluehtojen vastainen</td>
                        @if (mayAccept()) {
                            <td class="actions-cell"></td>
                        }
                    </tr>
                }
            </tbody>
        </table>
    } @else {
        <mat-spinner class="default-spinner"/>
    }
</mat-dialog-content>
<mat-dialog-actions align="end">
    @if (mayMarkAsSevere()) {
        <button mat-button (click)="markAsSevere()">Aseta räikeästi palveluehtojen vastaiseksi</button>
        <div class="fill-remaining-space"></div>
    }
    <button mat-button mat-dialog-close cdkFocusInitial>Sulje</button>
</mat-dialog-actions>
