import { ChangeDetectionStrategy, Component, computed, Inject, signal, Signal } from '@angular/core';
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { asRequired, brand, controlValuesSignal, CountryId, CountryInfo, disableWhen, EmailAddress, emptyToNull, enableWhen, hasText, PhoneNumber, ReferenceDataService, validateOvtInvoiceAddress, validatePhone } from "common";
import { OvtInvoiceAddress, PecBookingCandidateData, PecEndpoint, PecFairwayId, PecTestId, PecType, PilotageId, PilotagePecBillingEditData, PilotagePecTestEditData, VtsZoneInfo } from "apina-frontend";
import { MatInput } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { toSignal } from "@angular/core/rxjs-interop";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TextFieldComponent } from "../../forms/text-field/text-field.component";
import { TextareaFieldComponent } from "../../forms/textarea-field/textarea-field.component";
import { PhoneFieldComponent } from "../../forms/phone-field/phone-field.component";
import { EmailFieldComponent } from "../../forms/email-field/email-field.component";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { PecTypeNamePipe } from "../../common/pipes/pec-type-name.pipe";

@Component({
    selector: 'app-pilotage-pec-test',
    standalone: true,
    imports: [
        CdkTextareaAutosize,
        CommonDialogFormComponent,
        EmailFieldComponent,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInput,
        MatSelectModule,
        MatStepperModule,
        MatTabsModule,
        PhoneFieldComponent,
        ReactiveFormsModule,
        TextFieldComponent,
        TextareaFieldComponent,
        VerticalFormComponent,
        MatProgressSpinner,
        PecTypeNamePipe,
    ],
    templateUrl: './pilotage-pec-test.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "class": "!block",
    }
})
export class PilotagePecTestComponent implements CommonDialogFormDelegate {

    readonly form = new FormGroup({
        pecType: new FormControl<PecType | null>(null, Validators.required),
        fairwayIds: new FormControl<PecFairwayId[]>([], {nonNullable: true}),
        notes: new FormControl<string>("", {nonNullable: true}),
        name: new FormControl<string>("", {nonNullable: true}),
        email: new FormControl<EmailAddress>(null!, {validators: [Validators.email], nonNullable: true}),
        phone: new FormControl<PhoneNumber | null>(null, [validatePhone]),
        usesPilotageBillingDetails: new FormControl<boolean>(false, {nonNullable: true}),
        billing: new FormGroup({
            organizationName: new FormControl<string>("", {nonNullable: true}),
            businessId: new FormControl<string>("", {nonNullable: true}),
            addressLine1: new FormControl<string>("", {nonNullable: true}),
            addressLine2: new FormControl<string>("", {nonNullable: true}),
            countryId: new FormControl<CountryId | null>(null),
            email: new FormControl<EmailAddress | null>(brand(""), {validators: [Validators.email], nonNullable: true}),
            eInvoiceAddress: new FormControl<OvtInvoiceAddress | null>(brand(""), validateOvtInvoiceAddress),
        }),
    });

    readonly vtsZones: Signal<VtsZoneInfo[] | undefined>;
    readonly countries: Signal<CountryInfo[]>;
    readonly useEInvoiceAddress: Signal<boolean>;
    readonly usesPilotageBillingDetails: Signal<boolean>;
    readonly loading = signal(true);
    readonly pilotagePecTypes = [PecType.FAMILIARISATION_VOYAGE, PecType.PRACTICAL_PILOTAGE_TEST] as const;

    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly params: PilotagePecTestParams,
        private readonly pecEndpoint: PecEndpoint,
        referenceDataService: ReferenceDataService,
    ) {
        this.vtsZones = toSignal(pecEndpoint.findPecFairwaysByZone());
        this.countries = toSignal(referenceDataService.countries$, {initialValue: []});

        if (params.mode === "update") {
            this.form.controls.pecType.reset(params.type);
            this.form.controls.pecType.disable();

            pecEndpoint.getPilotagePecTestForEdit(params.id).subscribe(test => {
                this.form.controls.name.reset(test.candidateData.name ?? "");
                this.form.controls.email.reset(test.candidateData.email ?? brand(""));
                this.form.controls.phone.reset(test.candidateData.phone);
                this.form.controls.fairwayIds.reset(test.fairwayIds);
                this.form.controls.notes.reset(test.notes);

                if (test.billingData != null) {
                    this.form.controls.usesPilotageBillingDetails.reset(false);
                    this.form.controls.billing.reset(test.billingData);
                } else {
                    this.form.controls.usesPilotageBillingDetails.reset(true);
                }

                this.loading.set(false);
            });
        } else {
            this.loading.set(false);
        }

        // TODO: duplication
        // A bit ugly, but perhaps we can count on the name of Finland to be stable for a while
        const finlandId = computed(() => this.countries().find(it => it.name === "Suomi")?.id ?? -1);

        const country = controlValuesSignal(this.form.controls.billing.controls.countryId);
        this.useEInvoiceAddress = computed(() => country() === finlandId());
        this.usesPilotageBillingDetails = controlValuesSignal(this.form.controls.usesPilotageBillingDetails);

        disableWhen(this.usesPilotageBillingDetails, this.form.controls.billing);
        enableWhen(computed(() => !this.usesPilotageBillingDetails() && this.useEInvoiceAddress()), this.form.controls.billing.controls.eInvoiceAddress);
    }

    doSave(): Observable<void> {
        const type = this.form.controls.pecType.value!;
        const candidateData: PecBookingCandidateData = {
            name: emptyToNull(this.form.controls.name.value),
            email: emptyToNull(this.form.controls.email.value),
            phone: emptyToNull(this.form.controls.phone.value),
        };

        const billingForm = asRequired(this.form.controls.billing.value);
        const billingData: PilotagePecBillingEditData | null =
            this.form.controls.usesPilotageBillingDetails.value
                ? null
                : {
                    businessId: billingForm.businessId,
                    organizationName: billingForm.organizationName,
                    addressLine1: billingForm.addressLine1,
                    addressLine2: billingForm.addressLine2,
                    countryId: billingForm.countryId,
                    email: emptyToNull(billingForm.email),
                    eInvoiceAddress: hasText(billingForm.eInvoiceAddress) ? billingForm.eInvoiceAddress : null,
                };

        const fairwayIds = this.form.controls.fairwayIds.value!;
        const notes = this.form.controls.notes.value;

        const data: PilotagePecTestEditData = {
            candidateData, billingData, fairwayIds, notes
        };

        const mode = this.params.mode;
        switch (mode) {
            case "create":
                return this.createTest(this.params.pilotageId, type, data);
            case "update":
                return this.pecEndpoint.updatePilotagePecTest(this.params.id, data);
        }
    }

    private createTest(pilotageId: PilotageId, type: PecType, data: PilotagePecTestEditData): Observable<void> {
        switch (type) {
            case PecType.PRACTICAL_PILOTAGE_TEST:
                return this.pecEndpoint.createPracticalPilotageTest(pilotageId, data);
            case PecType.FAMILIARISATION_VOYAGE:
                return this.pecEndpoint.createFamiliarisationVoyage(pilotageId, data);
            case PecType.SHIP_SIMULATOR_TEST:
            case PecType.FAIRWAY_KNOWLEDGE_TEST:
                throw `unexpected type ${type}`;
        }
    }
}

interface CreatePecTestParams {
    mode: "create",
    pilotageId: PilotageId;
}

interface UpdatePecTestParams {
    mode: "update",
    id: PecTestId;
    type: PecType;
}

export type PilotagePecTestParams = CreatePecTestParams | UpdatePecTestParams;
