@if (!loading()) {
    <app-common-dialog-form
        dialogTitle="PEC-palvelu"
        [delegate]="this">

        <mat-tab-group class="h-[550px] min-w-[500px]">
            <mat-tab label="Perustiedot">
                <app-vertical-form class="mt-4">
                    <h3 class="form-section-title">Koe</h3>

                    <mat-form-field subscriptSizing="dynamic" appearance="fill">
                        <mat-label>Tyyppi</mat-label>
                        <mat-select [formControl]="form.controls.pecType">
                            <mat-select-trigger>
                                @if (form.controls.pecType.value; as pecType) {
                                    <div class="flex flex-row justify-start items-baseline gap-2">
                                        <div>{{ pecType | pecTypeName }}</div>
                                        <div class="text-de-emphasized text-sm">({{pecType | pecTypeName:'en'}})</div>
                                    </div>
                                } @else {
                                    <span>-</span>
                                }
                            </mat-select-trigger>
                            @for (pecType of pilotagePecTypes; track $index) {
                                <mat-option [value]="pecType">
                                    <div class="flex flex-row justify-between">
                                        <div>{{ pecType| pecTypeName }}</div>
                                        <div class="text-de-emphasized">{{pecType | pecTypeName:'en'}}</div>
                                    </div>
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field subscriptSizing="dynamic" appearance="fill">
                        <mat-label>Väylä</mat-label>
                        <mat-select [formControl]="form.controls.fairwayIds" [multiple]="true">
                            @for (zone of vtsZones(); track zone.id) {
                                <mat-optgroup [label]="zone.name">
                                    @for (fairway of zone.fairways; track fairway.id) {
                                        <mat-option [value]="fairway.id">{{ fairway.name }}</mat-option>
                                    }
                                </mat-optgroup>
                            }
                        </mat-select>
                    </mat-form-field>

                    <app-textarea-field label="Lisätietoja" [control]="form.controls.notes"/>

                    <h3 class="form-section-title">Kokeen suorittaja</h3>

                    <app-text-field label="Nimi" [control]="form.controls.name"/>
                    <app-email-field label="Sähköposti" [control]="form.controls.email"/>
                    <app-phone-field label="Puhelin" [control]="form.controls.phone"/>
                </app-vertical-form>
            </mat-tab>
            <mat-tab label="Laskutustiedot">
                <app-vertical-form class="mt-4">
                    <mat-checkbox [formControl]="form.controls.usesPilotageBillingDetails">Käyttää luotsauksen tietoja</mat-checkbox>

                    @if (!usesPilotageBillingDetails()) {
                        <app-text-field label="Yritys" [control]="form.controls.billing.controls.organizationName"/>
                        <app-text-field label="Yritysnumero" [control]="form.controls.billing.controls.businessId"/>
                        <app-text-field label="Osoiterivi 1" [control]="form.controls.billing.controls.addressLine1"/>
                        <app-text-field label="Osoiterivi 2" [control]="form.controls.billing.controls.addressLine2"/>

                        <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                            <mat-label>Maa</mat-label>
                            <mat-select [formControl]="form.controls.billing.controls.countryId">
                                <mat-option [value]="null">-</mat-option>
                                @for (country of countries(); track country.id) {
                                    <mat-option [value]="country.id">{{ country.name }}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>

                        <app-email-field label="Laskutussähköposti" [control]="form.controls.billing.controls.email"/>

                        @if (useEInvoiceAddress()) {
                            <mat-form-field floatLabel="always" subscriptSizing="dynamic">
                                <mat-label>Verkkolaskuosoite</mat-label>
                                <input type="text" matInput [formControl]="form.controls.billing.controls.eInvoiceAddress">
                            </mat-form-field>
                        }
                    }
                </app-vertical-form>
            </mat-tab>
        </mat-tab-group>
    </app-common-dialog-form>

} @else {
    <mat-spinner class="default-spinner"/>
}
