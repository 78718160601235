import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { PilotageEndpoint, PilotageId, PilotageScheduleSource, UpdateAnchorageArrivalTimeDto } from "apina-frontend";
import { currentDate, Instant, localDateTimeToInstant, MyMatDateTimePickerComponent } from "common";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { SelectScheduleSourceComponent } from "../../common/select-schedule-source/select-schedule-source.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { InputRowComponent } from "../../forms/input-row/input-row.component";
import { ScheduleSourceFieldComponent } from "../../forms/schedule-source-field/schedule-source-field.component";
import { DatetimeFieldComponent } from "../../forms/datetime-field/datetime-field.component";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";

@Component({
    templateUrl: "./update-anchorage-arrival-time.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatIconModule,
        MyMatDateTimePickerComponent,
        SelectScheduleSourceComponent,
        CommonDialogFormComponent,
        ReactiveFormsModule,
        InputRowComponent,
        ScheduleSourceFieldComponent,
        DatetimeFieldComponent,
        VerticalFormComponent,
    ],
})
export class UpdateAnchorageArrivalTimeComponent implements CommonDialogFormDelegate {

    readonly form = new FormGroup({
        estimatedAnchorageArrivalTime: new FormControl<Instant | null>(this.params.estimatedAnchorageArrivalTime, Validators.required),
        scheduleSource: new FormControl<PilotageScheduleSource | null>(null, Validators.required)
    });

    constructor(@Inject(MAT_DIALOG_DATA) readonly params: UpdateAnchorageArrivalTimeParams,
                private readonly pilotageEndpoint: PilotageEndpoint) {
    }

    readonly startOfDay = localDateTimeToInstant(currentDate().atStartOfDay());

    doSave(): Observable<unknown> {
        const dto: UpdateAnchorageArrivalTimeDto = {
            estimatedAnchorageArrivalTime: this.form.controls.estimatedAnchorageArrivalTime.value!,
            scheduleSource: this.form.controls.scheduleSource.value!
        };

        return this.pilotageEndpoint.updateEstimatedAnchorageArrivalTime(this.params.pilotageId, dto);
    }
}

export interface UpdateAnchorageArrivalTimeParams {
    pilotageId: PilotageId;
    vesselName: string,
    estimatedAnchorageArrivalTime: Instant | null;
}
