@if (viewData(); as viewData) {
    <app-common-dialog-form
        class="min-w-[500px]"
        [delegate]="viewData"
        dialogTitle="{{viewData.vesselName}}: {{viewData.route}}">

        <app-vertical-form>
            <mat-form-field>
                <mat-label>Vakiohuomautus</mat-label>
                <mat-select [formControl]="viewData.form.controls.standardNoticeId">
                    <mat-option [value]="null">&mdash;</mat-option>
                    @for (standardNotice of viewData.standardNotices; track standardNotice.id) {
                        <mat-option [value]="standardNotice.id">{{ standardNotice.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <app-textarea-field label="Huomautus" [control]="viewData.form.controls.notice"/>
        </app-vertical-form>
    </app-common-dialog-form>
} @else {
    <mat-spinner class="default-spinner"/>
}
