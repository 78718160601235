@if (viewData(); as viewData) {
    <app-common-dialog-form
        class="min-w-[600px]"
        [delegate]="this"
        dialogTitle="{{params.vesselName}}: {{params.route}}"
        saveText="Muuta tilausaikaa">

        <app-vertical-form>
            @if (viewData.showPilotOnBoardWarning) {
                <p class="text-orange">Luotsi on laivalla</p>
            }

            <app-input-row>
                <mat-form-field>
                    <mat-label>Tilausaika</mat-label>
                    <app-mat-date-time-picker [formControl]="form.controls.orderTime" [required]="true"/>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Tiedon lähde</mat-label>
                    <app-select-schedule-source [formControl]="form.controls.source" [type]="SelectScheduleSourceType.ORDER"/>
                </mat-form-field>
            </app-input-row>

            @if (showPilot) {
                <app-input-row>
                    <mat-form-field>
                        <mat-label>Toimitusaika</mat-label>
                        <app-mat-date-time-picker [formControl]="form.controls.pilotBoardingTime" [required]="true"/>
                        <mat-icon matIconSuffix>calendar_today</mat-icon>
                        @if (params.pilotBoardingTime && (form.controls.pilotBoardingTime.value == null || !params.pilotBoardingTime.equals(form.controls.pilotBoardingTime.value))) {
                            <mat-hint>Toimitusaikaa siirretty (oli <a href="" tabindex="-1" (click)="resetPilotEtaToOriginal($event)">{{ params.pilotBoardingTime | helsinkiDate:'HH:mm' }})</a></mat-hint>
                        }
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Luotsi</mat-label>
                        <input matInput disabled [value]="pilots">
                    </mat-form-field>
                </app-input-row>
            }

            @if (form.controls.latePilotBoardingTimeReason.enabled) {
                <mat-form-field>
                    <mat-label>Myöhäisen toimitusajan syy</mat-label>
                    <mat-select [formControl]="form.controls.latePilotBoardingTimeReason">
                        @for (v of latePilotBoardingTimeReasonSelections; track v.value) {
                            <mat-option [value]="v.value">{{ v.label }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            }

            <mat-checkbox [formControl]="form.controls.transferFee">Peritään siirtomaksu</mat-checkbox>

            <mat-form-field>
                <mat-label>Kommentti muutokselle</mat-label>
                <textarea matInput [formControl]="form.controls.changeLogComment" cdkTextareaAutosize></textarea>
            </mat-form-field>
        </app-vertical-form>
    </app-common-dialog-form>
} @else {
    <mat-spinner class="default-spinner"/>
}
