<app-common-dialog-form
    [delegate]="this"
    dialogTitle="Tilausajan siirtomaksu"
    class="min-w-[300px]">

    <app-vertical-form>
        <app-transfer-fee-field label="Siirtomaksu" [control]="form.controls.transferFee"/>
<!--        <app-service-fee-field label="Palvelumaksu" [control]="form.controls.serviceFee"/>-->
    </app-vertical-form>
</app-common-dialog-form>
