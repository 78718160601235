@if (chainData(); as data) {
    <h3 mat-dialog-title>Luotsausketjun muokkaus - {{ data.title }}</h3>

    <mat-dialog-content>

        <table class="default-table">
            <tbody>

                @if (mayAddBeforeChain()) {
                    <tr>
                        <td colspan="2">
                        </td>
                        <td class="w-[150px]!">
                            <button mat-icon-button matTooltip="Lisää reitti" (click)="addLeg('', 0)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </td>
                    </tr>
                }

                @for (leg of legs(); let index = $index; track index) {
                    <tr>
                        <td>{{ leg.startCode }}&mdash;{{ leg.endCode }}</td>
                        <td>{{ leg.name }}</td>
                        <td class="w-[150px]">
                            <button mat-icon-button matTooltip="Lisää reitti" (click)="addLeg(leg.endCode, index+1)" [disabled]="!leg.editable">
                                <mat-icon>add</mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="Poista reitti" (click)="removeLeg(index)" [disabled]="!leg.editable">
                                <mat-icon>remove</mat-icon>
                            </button>
                            <button mat-icon-button matTooltip="Vaihda reitti" (click)="changeRoute(leg.startCode, index)" [disabled]="!leg.editable">
                                <mat-icon>sync_alt</mat-icon>
                            </button>
                        </td>
                    </tr>
                }

            <tr>
                @if (continuationChain(); as chain) {
                    <td class="!text-de-emphasized">{{ chain.startCode }}&mdash;{{ chain.endCode }}</td>
                    <td>Jatkoketju</td>
                } @else {
                    <td></td>
                    <td>Ei jatkoketjua</td>
                }
                <td class="actions-cell">
                    <button mat-icon-button matTooltip="Lisää jatkoketju" (click)="selectContinuationChain()" [disabled]="continuationChain() != null">
                        <mat-icon>add</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Poista jatkoketju" (click)="removeContinuationChain()" [disabled]="continuationChain() == null">
                        <mat-icon>remove</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Vaihda jatkoketju" (click)="selectContinuationChain()" [disabled]="continuationChain() == null">
                        <mat-icon>sync_alt</mat-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>


        @if (!valid()) {
            <div class="text-error my-4">
                @if (legs().length === 0) {
                    Ketjussa ei ole yhtään luotsausta.
                } @else {
                    Ketjun rakenne on virheellinen. Jokaisen luotsauksen reitin pitää lähteä samasta pisteetä, johon edellinen luotsaus päättyy.
                }
            </div>
        }

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ 'common.cancel' | translate }}</button>
        <app-spinner-button [disabled]="!valid() || !modified()" [active]="saving()" (spinnerClick)="save()">{{ 'common.save' | translate }}</app-spinner-button>
    </mat-dialog-actions>
} @else {
    <mat-spinner class="default-spinner"/>
}
