import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ErrorService, SpinnerButtonComponent } from "common";
import { PilotageEndpoint, PilotageId } from "apina-frontend";
import { PilotageService } from "../pilotage.service";
import { NewPilotageSourceMode } from "../new-pilotage/new-pilotage-from.resolver";
import { MatButtonModule } from "@angular/material/button";

@Component({
    templateUrl: './cancel-pilotage-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        MatButtonModule,
        SpinnerButtonComponent,
    ],
})
export class CancelPilotageDialogComponent {

    readonly progress = signal(false);
    readonly vesselName: string;
    readonly showCreateFromOption: boolean;

    constructor(private readonly dialogRef: MatDialogRef<CancelPilotageDialogComponent>,
                private readonly errorService: ErrorService,
                private readonly pilotageEndpoint: PilotageEndpoint,
                private readonly pilotageService: PilotageService,
                @Inject(MAT_DIALOG_DATA) private readonly params: CancelPilotageDialogComponentParams) {
        this.vesselName = this.params.vesselName;
        this.showCreateFromOption = this.params.showCreateFromOption;
    }

    accept(e: Event, newPilotage: boolean): void {
        e.preventDefault();
        e.stopPropagation();

        this.progress.set(true);

        this.pilotageEndpoint.cancelPilotage(this.params.pilotageId).subscribe({
            next: ok => {
                this.dialogRef.close(ok);
                if (newPilotage)
                    this.pilotageService.openCreateNewPilotageDialog({from: this.params.pilotageId, mode: NewPilotageSourceMode.CANCELLED});
            },
            error: error => {
                this.progress.set(false);
                this.errorService.showUpdateError(error);
            }
        });
    }
}

export interface CancelPilotageDialogComponentParams {
    pilotageId: PilotageId;
    vesselName: string;
    showCreateFromOption: boolean;
}
