import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { BerthId, PilotageEndpoint, PilotageId, PilotageRouteInfo, PilotageScheduleSource, RestoreToEstimateParams } from "apina-frontend";
import { Instant, MyMatDateTimePickerComponent } from "common";
import { routeDescription } from "../../domain/routes";
import { Observable } from "rxjs";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { SelectScheduleSourceComponent } from "../../common/select-schedule-source/select-schedule-source.component";
import { SelectBerthComponent } from "../../common/select-berth/select-berth.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { BerthFieldComponent } from "../../forms/berth-field/berth-field.component";
import { DatetimeFieldComponent } from "../../forms/datetime-field/datetime-field.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";
import { ScheduleSourceFieldComponent } from "../../forms/schedule-source-field/schedule-source-field.component";

@Component({
    templateUrl: './restore-to-estimate.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonDialogFormComponent,
        MatFormFieldModule,
        MatIconModule,
        MyMatDateTimePickerComponent,
        ReactiveFormsModule,
        SelectBerthComponent,
        SelectScheduleSourceComponent,
        VerticalFormComponent,
        BerthFieldComponent,
        DatetimeFieldComponent,
        InputRowComponent,
        ScheduleSourceFieldComponent,
    ],
})
export class RestoreToEstimateComponent implements CommonDialogFormDelegate {

    readonly title: string;
    readonly form: FormGroup<RestoreToEstimateForm>;

    constructor(@Inject(MAT_DIALOG_DATA) readonly params: RestoreToEstimateComponentParams,
                private readonly pilotageEndpoint: PilotageEndpoint) {

        this.title = `${params.vesselName}: ${routeDescription(params.route)}`;

        this.form = new FormGroup<RestoreToEstimateForm>({
            eta: new FormControl(params.eta, {validators: Validators.required, nonNullable: true}),
            source: new FormControl(null, Validators.required),
            startBerthId: new FormControl(params.route.start.berth?.id ?? null),
            endBerthId: new FormControl(params.route.end.berth?.id ?? null),
        });
    }

    doSave(): Observable<void> {
        const params: RestoreToEstimateParams = {
            eta: this.form.controls.eta.value,
            source: this.form.controls.source.value!,
            startBerthId: this.form.controls.startBerthId.value,
            endBerthId: this.form.controls.endBerthId.value,
        };

        return this.pilotageEndpoint.restoreToEstimate(this.params.pilotageId, params);
    }
}

interface RestoreToEstimateForm {
    endBerthId: FormControl<BerthId | null>;
    eta: FormControl<Instant>;
    source: FormControl<PilotageScheduleSource | null>;
    startBerthId: FormControl<BerthId | null>;
}

export interface RestoreToEstimateComponentParams {
    pilotageId: PilotageId;
    eta: Instant;
    vesselName: string;
    route: PilotageRouteInfo;
}
