<mat-form-field class="w-full">
    <mat-label>{{ label() }}</mat-label>

    <mat-select [formControl]="control()">
        <mat-option [value]="0">Ei siirtomaksua</mat-option>
        <mat-option [value]="1">1 siirtomaksu</mat-option>
        <mat-option [value]="2">2 siirtomaksua</mat-option>
    </mat-select>

    @if (hint() != null) {
        <mat-hint align="end">{{ hint() }}</mat-hint>
    }
</mat-form-field>
