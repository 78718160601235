<h3 mat-dialog-title>Vahvista toimitusaika</h3>

<mat-dialog-content>
    <div class="grid grid-cols-2 gap-2">
        <app-datetime-field label="ETA" [control]="form.controls.eta" [required]="true"/>
        <app-readonly-field label="Tiedon lähde" [value]="sourceName"/>

        <mat-form-field>
            <mat-label>Toimitusaika</mat-label>
            <app-mat-date-time-picker [formControl]="form.controls.pilotBoardingTime"/>
            <mat-icon matIconSuffix>calendar_today</mat-icon>
        </mat-form-field>

        <app-readonly-field label="Luotsi" [value]="pilots"/>

        @if (needsLatePilotBoardingTimeReason) {
            <mat-form-field class="col-span-2">
                <mat-label>Myöhäisen toimitusajan syy</mat-label>
                <mat-select [formControl]="form.controls.latePilotBoardingTimeReason">
                    @for (v of latePilotBoardingTimeReasonSelections; track v.value) {
                        <mat-option [value]="v.value">{{ v.label }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }

        <app-pilot-delivery-actions
            class="col-span-2"
            [hasTosViolations]="hasTosViolations"
            [form]="form.controls.pilotDeliveryActions"
            [orderTime]="form.controls.orderTime.value"
            [pilotBoardingTime]="form.controls.pilotBoardingTime.value"
        />
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [color]="form.controls.pilotBoardingTime.value ? 'primary' : 'warn'" [disabled]="!changeReasonOk" (click)="save()">{{ form.controls.pilotBoardingTime.value ? 'Vahvista' : 'Kiinnitä ilman toimitusaikaa' }}</button>
</mat-dialog-actions>
