@if (observation(); as obs) {
    <div style="min-width: 480px">
        <h3 mat-dialog-title>Luotsaustilauksen tarkkailu</h3>

        <mat-dialog-content>
            <app-pilotage-observation [observation]="obs" (closed)="close()"/>
        </mat-dialog-content>
    </div>
} @else {
    <mat-spinner class="default-spinner"/>
}
