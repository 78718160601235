import { Pipe, PipeTransform } from "@angular/core";
import { PecType } from "apina-frontend";
import { englishPecTypeNames, pecTypeNames } from "../../domain/pec";

@Pipe({
    name: 'pecTypeName',
    standalone: true,
    pure: true,
})
export class PecTypeNamePipe implements PipeTransform {

    transform(value: PecType, language: "fi" | "en" = "fi"): string {
        switch (language) {
            case "fi":
                return pecTypeNames[value];
            case "en":
                return englishPecTypeNames[value];
        }
    }
}
