<app-vertical-form>
    @if (showPilotageInfo()) {
        <p>
            {{ pilotageName() }}<br/>ETA/ETD: {{ observation().startTime | helsinkiDate: 'dd.MM HH:mm' }}
        </p>
    }

    <app-datetime-field label="Takaraja" [control]="editForm.controls.deadline"/>
    <app-textarea-field label="Muistiinpanot" [control]="editForm.controls.notes" [rows]="7"/>
</app-vertical-form>

<div class="mt-4 text-right">
    @if (alreadyPersisted()) {
        <button mat-button (click)="close()">Peruuta</button>
        <button mat-button color="warn" (click)="delete()" [disabled]="updating()">Lopeta tarkkailu</button>
        <button mat-button (click)="save()" [disabled]="updating() || editForm.invalid">Tallenna</button>
    } @else {
        <button mat-button (click)="close()">Peruuta</button>
        <button mat-button (click)="save()" [disabled]="updating() || editForm.invalid">Tarkkaile</button>
    }
</div>
