<app-common-dialog-form [dialogTitle]="params.vesselName" [delegate]="this">
    <app-vertical-form>
        <app-input-row>
            <mat-form-field>
                <mat-label>Saapumisaika ankkuriin</mat-label>
                <app-mat-date-time-picker [formControl]="form.controls.estimatedAnchorageArrivalTime" [startAt]="startOfDay" required/>
                <mat-icon matIconSuffix>calendar_today</mat-icon>
            </mat-form-field>
            <app-schedule-source-field label="Aikataulutiedon lähde" [control]="form.controls.scheduleSource"/>
        </app-input-row>
    </app-vertical-form>
</app-common-dialog-form>

