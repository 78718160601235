import { PilotingMethod, PilotingMethodWithCaptain } from "apina-frontend";
import { LabeledValue, labeledValue } from "./labeled-value";

export const labeledPilotingMethods: ReadonlyArray<Readonly<LabeledValue<PilotingMethod>>> = [
    labeledValue(PilotingMethod.OFFICER_WAS_MONITORING, "Luotsi ohjaili, vahtipäällikkö monitoroi"),
    labeledValue(PilotingMethod.OFFICER_WAS_PRESENT, "Luotsi ohjaili, vahtipäällikkö paikalla mutta ei osallistunut"),
    labeledValue(PilotingMethod.ALONE, "Luotsi ohjaili, vahtipäällikkö ei paikalla"),
    labeledValue(PilotingMethod.OFFICER_WAS_STEERING, "Vahtipäällikkö ohjaili, luotsi monitoroi"),
];

export const labeledPilotingMethodsWithCaptain: ReadonlyArray<Readonly<LabeledValue<PilotingMethodWithCaptain>>> = [
    labeledValue(PilotingMethodWithCaptain.CAPTAIN_WAS_MONITORING, "Luotsi ohjaili, päällikkö monitoroi mutta ei osallistunut (pl. köysikiinnitykset)"),
    labeledValue(PilotingMethodWithCaptain.CAPTAIN_TOOK_PART, "Luotsi ohjaili, päällikkö monitoroi ja osallistui"),
    labeledValue(PilotingMethodWithCaptain.CAPTAIN_WAS_STEERING_AND_FOLLOWING_INSTRUCTIONS, "Päällikkö ohjaili luotsin ohjeiden mukaan"),
    labeledValue(PilotingMethodWithCaptain.OFFICER_WAS_STEERING_PILOT_WAS_MONITORING, "Päällikkö ohjaili, luotsi monitoroi"),
];

export const labeledTugCounts: ReadonlyArray<Readonly<LabeledValue<number>>> = [
    labeledValue(0, "0"),
    labeledValue(1, "1"),
    labeledValue(2, "2"),
    labeledValue(3, "Enemmän kuin 2"),
];
