import { ChangeDetectionStrategy, Component, computed, Inject, Signal } from '@angular/core';
import { BerthId, FindRouteDto, PilotageDetails, PilotageEditDetails, PilotageEndpoint, PilotageFixupParams, PilotageScheduleSource, RouteEndpoint, RouteId } from "apina-frontend";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { ALL_PILOTAGE_STATES, asRequired, controlValuesSignal, PilotageState, PilotageStateNamePipe, SpinnerButtonComponent } from "common";
import { MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { Observable } from "rxjs";
import { DatetimeFieldComponent } from "../../forms/datetime-field/datetime-field.component";
import { Instant } from "@js-joda/core";
import { ScheduleSourceFieldComponent } from "../../forms/schedule-source-field/schedule-source-field.component";
import { createDraftControls, EditDraftFieldsComponent } from "../../common/edit-draft-fields/edit-draft-fields.component";
import { ReadonlyFieldComponent } from "../../forms/readonly-field/readonly-field.component";
import { TextareaFieldComponent } from "../../forms/textarea-field/textarea-field.component";
import { BerthFieldComponent } from "../../forms/berth-field/berth-field.component";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { SelectRouteComponent } from "../../common/select-route/select-route.component";
import { toSignal } from "@angular/core/rxjs-interop";

@Component({
    selector: 'app-fixup-pilotage',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatButton,
        MatDialogModule,
        MatFormFieldModule,
        MatLabel,
        MatOption,
        MatSelect,
        SpinnerButtonComponent,
        TranslateModule,
        PilotageStateNamePipe,
        VerticalFormComponent,
        InputRowComponent,
        CommonDialogFormComponent,
        DatetimeFieldComponent,
        ScheduleSourceFieldComponent,
        EditDraftFieldsComponent,
        ReadonlyFieldComponent,
        TextareaFieldComponent,
        BerthFieldComponent,
        MatRadioButton,
        MatRadioGroup,
        SelectRouteComponent,
    ],
    templateUrl: './fixup-pilotage.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "class": "!block min-w-[1000px]"
    }
})
export class FixupPilotageComponent implements CommonDialogFormDelegate {

    readonly pilotage: PilotageDetails = this.params.details.pilotage;
    readonly form = new FormGroup({
        state: new FormControl<PilotageState>(this.pilotage.state, {nonNullable: true, validators: Validators.required}),
        eta: new FormControl<Instant>(this.pilotage.eta, {nonNullable: true, validators: Validators.required}),
        orderTime: new FormControl<Instant | null>(this.pilotage.orderTime),
        pilotBoardingTime: new FormControl<Instant | null>(this.pilotage.pilotBoardingTime),
        startTime: new FormControl<Instant>(this.pilotage.startTime, {nonNullable: true, validators: Validators.required}),
        endTime: new FormControl<Instant>(this.pilotage.endTime, {nonNullable: true, validators: Validators.required}),
        scheduleSource: new FormControl<PilotageScheduleSource>(this.pilotage.scheduleSource!, {nonNullable: true, validators: Validators.required}),
        routeId: new FormControl<RouteId>(this.pilotage.route.id, {nonNullable: true, validators: Validators.required}),
        startBerthId: new FormControl<BerthId | null>(this.pilotage.route.start.berth?.id ?? null),
        endBerthId: new FormControl<BerthId | null>(this.pilotage.route.end.berth?.id ?? null),
        ...createDraftControls(this.pilotage.drafts),
        changeReason: new FormControl<string>("", {nonNullable: true, validators: Validators.required}),
    });

    readonly selectedRoute: Signal<FindRouteDto | undefined>;
    readonly ALL_PILOTAGE_STATES = ALL_PILOTAGE_STATES;

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly params: FixupPilotageParams,
        private readonly pilotageEndpoint: PilotageEndpoint,
        routeEndpoint: RouteEndpoint,
    ) {
        const routes = toSignal(routeEndpoint.getRoutes());
        const selectedRouteId = controlValuesSignal(this.form.controls.routeId);

        this.selectedRoute = computed(() => {
            const routeId = selectedRouteId();
            return routes()?.find(it => it.id === routeId);
        });
    }

    doSave(): Observable<unknown> {
        const params: PilotageFixupParams = asRequired(this.form.value);
        return this.pilotageEndpoint.fixupPilotage(this.pilotage.id, params);
    }
}

export interface FixupPilotageParams {
    details: PilotageEditDetails;
}
