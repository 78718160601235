@if (viewData(); as viewData) {
    <app-common-dialog-form
        class="w-[500px]"
        [delegate]="this"
        dialogTitle="{{ viewData.vesselName}}: {{ viewData.routeDescription }} {{ viewData.startTime | helsinkiDate:'date-time-with-year' }}"
        saveText="Vaihda asiakas">

        <app-vertical-form>
            <mat-form-field>
                <mat-label>Toimipaikka</mat-label>
                <app-select-customer-office [formControl]="form.controls.customerId"/>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Laskutustoimipaikka</mat-label>
                <app-select-customer-office [formControl]="form.controls.billingCustomerId"/>
            </mat-form-field>
        </app-vertical-form>
    </app-common-dialog-form>
} @else {
    <mat-spinner class="default-spinner"/>
}
