<h3 mat-dialog-title>Aseta herätysaika</h3>

<mat-dialog-content>
    <app-vertical-form>
        <mat-form-field>
            <mat-label>Aika luotsauksen alkuun</mat-label>
            <input matInput cdkFocusInitial [formControl]="form.controls.difference" placeholder="hhmm" (keyup.enter)="save()"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Kellonaika</mat-label>
            <input matInput [formControl]="form.controls.time" (keyup.enter)="save()">
        </mat-form-field>
    </app-vertical-form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined">Peruuta</button>
    <app-spinner-button [disabled]="form.invalid" (spinnerClick)="save()" [active]="saving()">Tallenna</app-spinner-button>
</mat-dialog-actions>
