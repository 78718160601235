<h3 mat-dialog-title>Peru luotsaus alukselle {{ vesselName }}?</h3>

<mat-dialog-content>
    Peruttu luotsaus laskutetaan.
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="basic" [mat-dialog-close]="false">Peruuta</button>
    <app-spinner-button [color]="showCreateFromOption ? undefined : 'primary'" (spinnerClick)="accept($event, false)" [active]="progress()">Peru luotsaus</app-spinner-button>
    @if (showCreateFromOption) {
        <app-spinner-button color="primary" (spinnerClick)="accept($event, true)" [active]="progress()">Peru ja luo uusi</app-spinner-button>
    }
</mat-dialog-actions>
