@if (viewData(); as viewData) {
    <h3 mat-dialog-title>Päätä luotsaus alukselle {{ viewData.pilotage.vessel.name }}?</h3>

    <mat-dialog-content>
        <app-vertical-form>
            <app-input-row>
                <mat-form-field>
                    <mat-label>Luotsauksen alkaminen</mat-label>
                    <app-mat-date-time-picker [formControl]="viewData.form.controls.startTime" [required]="true"/>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Luotsauksen päättyminen</mat-label>
                    <app-mat-date-time-picker [formControl]="viewData.form.controls.endTime" [required]="true"/>
                    <mat-icon matIconSuffix>calendar_today</mat-icon>
                </mat-form-field>
            </app-input-row>
        </app-vertical-form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button color="basic" [mat-dialog-close]="false">{{ 'common.cancel' | translate }}</button>
        <app-spinner-button (spinnerClick)="endPilotage(viewData.form)" [active]="progress()" [disabled]="viewData.form.invalid">Päätä luotsaus</app-spinner-button>
    </mat-dialog-actions>
} @else {
    <mat-spinner class="default-spinner"/>
}
