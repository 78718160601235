import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { LatePilotBoardingTimeReason, PilotageEndpoint, PilotageId, PilotageScheduleSource, TosComplianceStatus, UpdatePilotBoardingTimeParams } from "apina-frontend";
import { arrayOfNotNull, ErrorService, Instant, MyMatDateTimePickerComponent, pilotageScheduleSourceNames, PilotageState } from "common";
import { LabeledValue } from "../../domain/labeled-value";
import { LATE_PILOT_BOARDING_TIME_REASON_SELECTIONS, needsLatePilotBoardingTimeReason } from "../../domain/pilot-boarding-time";
import { newPilotDeliveryActionsForm, PilotDeliveryActionsComponent, PilotDeliveryActionsForm, resolveActionsTakenToDeliverPilot } from "../pilot-delivery-actions/pilot-delivery-actions.component";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { DatetimeFieldComponent } from "../../forms/datetime-field/datetime-field.component";
import { ReadonlyFieldComponent } from "../../forms/readonly-field/readonly-field.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";

@Component({
    templateUrl: './set-pilot-boarding-time.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        PilotDeliveryActionsComponent,
        MyMatDateTimePickerComponent,
        MatDialogModule,
        MatInputModule,
        ReactiveFormsModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        VerticalFormComponent,
        DatetimeFieldComponent,
        ReadonlyFieldComponent,
        InputRowComponent,
    ],
})
export class SetPilotBoardingTimeComponent {

    readonly form: FormGroup<SetPilotBoardingTimeForm>;
    readonly sourceName: string;
    readonly pilots: string;
    readonly hasTosViolations: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly params: SetPilotBoardingTimeComponentParams,
        private readonly pilotageEndpoint: PilotageEndpoint,
        private readonly errorService: ErrorService,
        private readonly dialogRef: MatDialogRef<SetPilotBoardingTimeComponent, UpdatePilotBoardingTimeParams>,
    ) {
        this.form = new FormGroup({
            eta: new FormControl(params.startTime),
            pilotBoardingTime: new FormControl(params.pilotBoardingTime ?? params.startTime),
            orderTime: new FormControl(params.orderTime ?? params.startTime),
            latePilotBoardingTimeReason: new FormControl<LatePilotBoardingTimeReason | null>(null),
            pilotDeliveryActions: newPilotDeliveryActionsForm(),
        });

        this.form.controls.orderTime.disable();
        this.form.controls.eta.disable();

        this.sourceName = pilotageScheduleSourceNames[params.source];
        this.pilots = arrayOfNotNull(params.pilot1Initials, params.pilot2Initials).join(" + ");
        this.hasTosViolations = params.tosComplianceStatus !== TosComplianceStatus.FOLLOWS_TOS;
    }

    save(): void {
        const data: UpdatePilotBoardingTimeParams = {
            pilotBoardingTime: this.form.controls.pilotBoardingTime.value,
            latePilotBoardingTimeReason: this.form.controls.latePilotBoardingTimeReason.value,
            actionsTakenToDeliverPilot: resolveActionsTakenToDeliverPilot(this.form.controls.pilotDeliveryActions),
        };

        if (this.params.save) {
            this.pilotageEndpoint.updatePilotBoardingTime(this.params.pilotageId, data).subscribe({
                next: () => this.dialogRef.close(data),
                error: e => this.errorService.showUpdateError(e)
            });
        } else {
            this.dialogRef.close(data);
        }
    }

    get needsLatePilotBoardingTimeReason(): boolean {
        return needsLatePilotBoardingTimeReason(this.params.startTime, this.params.pilotBoardingTime, this.form.controls.eta.value, this.form.controls.pilotBoardingTime.value, this.params.state);
    }

    get latePilotBoardingTimeReasonSelections(): readonly LabeledValue<LatePilotBoardingTimeReason>[] {
        return LATE_PILOT_BOARDING_TIME_REASON_SELECTIONS;
    }

    get changeReasonOk(): boolean {
        return !this.needsLatePilotBoardingTimeReason || this.form.controls.latePilotBoardingTimeReason.value != null;
    }
}

interface SetPilotBoardingTimeForm {
    eta: FormControl<Instant | null>;
    pilotBoardingTime: FormControl<Instant | null>;
    latePilotBoardingTimeReason: FormControl<LatePilotBoardingTimeReason | null>;
    orderTime: FormControl<Instant | null>;
    pilotDeliveryActions: PilotDeliveryActionsForm
}

export interface SetPilotBoardingTimeComponentParams {
    pilotageId: PilotageId;
    state: PilotageState;
    source: PilotageScheduleSource;
    startTime: Instant;
    pilotBoardingTime: Instant | null;
    orderTime: Instant | null;
    pilot1Initials: string | null;
    pilot2Initials: string | null;
    tosComplianceStatus: TosComplianceStatus;
    save: boolean;
}
