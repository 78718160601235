<h3 mat-dialog-title>Reittiketjun valinta</h3>

<mat-dialog-content>
    <form class="flex flex-row gap-4 pt-2" (submit)="$event.preventDefault(); search()">
        <mat-form-field appearance="outline" class="w-[120px]">
            <mat-label>Alkupiste</mat-label>
            <input matInput type="text" [formControl]="form.controls.startCode" cdkFocusInitial autofocus>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-[120px]">
            <mat-label>Päätepiste</mat-label>
            <input matInput type="text" [formControl]="form.controls.endCode">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Nimi</mat-label>
            <input matInput type="text" [formControl]="form.controls.name">
        </mat-form-field>

        <button mat-button type="submit">Hae</button>
    </form>

    @if (searchResults(); as result) {
        <table class="default-table">
            <thead>
            <tr>
                <th>Alku</th>
                <th>Loppu</th>
                <th>Pituus</th>
                <th>Oletus&shy;kesto</th>
                <th>Nimi</th>
            </tr>
            </thead>
            <tbody>
                @for (chain of result; track chain.id) {
                    <tr (click)="selectRoute(chain)" class="hover:cursor-pointer hover:bg-gray-100">
                        <td [matTooltip]="chain.startName">{{ chain.startCode }}</td>
                        <td [matTooltip]="chain.endName">{{ chain.endCode }}</td>
                        <td>{{ chain.miles }} nm</td>
                        <td>{{ chain.defaultDuration | minutesDuration }}</td>
                        <td [matTooltip]="chain.description ?? ''">{{ chain.name }}</td>
                    </tr>
                }
            </tbody>
        </table>
    } @else {
        <mat-spinner class="default-spinner"/>
    }
</mat-dialog-content>
