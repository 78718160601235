import { ChangeDetectionStrategy, Component, Inject, signal, Signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { ErrorService, getCurrentTimeWithinMinute, greaterThanInstant, Instant, MyMatDateTimePickerComponent, SpinnerButtonComponent } from "common";
import { EndPilotageParams, PilotageDetails, PilotageEndpoint, PilotageId } from "apina-frontend";
import { map } from "rxjs/operators";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";

@Component({
    templateUrl: './end-pilotage.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatIconModule,
        ReactiveFormsModule,
        SpinnerButtonComponent,
        TranslateModule,
        MyMatDateTimePickerComponent,
        VerticalFormComponent,
        InputRowComponent,
    ],
})
export class EndPilotageComponent {

    readonly viewData: Signal<EndPilotageViewData | undefined>;
    readonly progress = signal(false);

    constructor(private readonly dialogRef: MatDialogRef<EndPilotageComponent>,
                private readonly errorService: ErrorService,
                private readonly pilotageEndpoint: PilotageEndpoint,
                @Inject(MAT_DIALOG_DATA) private readonly params: EndPilotageComponentParams) {

        this.viewData = toSignal(pilotageEndpoint.getPilotageDetails(params.pilotageId).pipe(map(pilotage => {
            const form = new FormGroup<EndPilotageViewForm>({
                startTime: new FormControl<Instant>(pilotage.startTime, {validators: Validators.required, nonNullable: true}),
                endTime: new FormControl<Instant>(getCurrentTimeWithinMinute(), {validators: Validators.required, nonNullable: true})
            });

            form.controls.endTime.addValidators(greaterThanInstant(form.controls.startTime));
            form.controls.startTime.valueChanges.subscribe(() => form.controls.endTime.updateValueAndValidity());

            return {pilotage, form};
        })));
    }

    endPilotage(form: FormGroup<EndPilotageViewForm>): void {
        const params = form.value as EndPilotageParams;
        this.progress.set(true);

        this.pilotageEndpoint.endPilotage(this.params.pilotageId, params).subscribe({
            next: () => {
                this.progress.set(false);
                this.dialogRef.close(true);
            },
            error: e => {
                this.progress.set(false);
                this.errorService.showUpdateError(e);
            }
        });
    }
}

interface EndPilotageViewForm {
    startTime: FormControl<Instant>;
    endTime: FormControl<Instant>;
}

interface EndPilotageViewData {
    pilotage: PilotageDetails;
    form: FormGroup<EndPilotageViewForm>;
}

export interface EndPilotageComponentParams {
    pilotageId: PilotageId;
}
