<app-common-dialog-form
    dialogTitle="Luotsaustilauksen korjaus"
    rightTitle="Tunniste: {{ pilotage.id }}"
    [delegate]="this">

    <app-vertical-form>
        <h3 class="form-section-title">Tilaus</h3>

        <app-input-row>
            <mat-form-field>
                <mat-label>Vaihe</mat-label>
                <mat-select [formControl]="form.controls.state">
                    @for (state of ALL_PILOTAGE_STATES; track $index) {
                        <mat-option [value]="state">{{ state | pilotageStateName }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            <app-schedule-source-field label="Tiedon lähde" [control]="form.controls.scheduleSource"/>
            <div></div>
        </app-input-row>

        <app-input-row>
            <app-datetime-field label="ETA/ETD" [control]="form.controls.eta" [required]="true"/>
            <app-datetime-field label="Tilausaika" [control]="form.controls.orderTime"/>
            <app-datetime-field label="Toimitusaika" [control]="form.controls.pilotBoardingTime"/>
        </app-input-row>
        <app-input-row>
            <app-datetime-field label="Alkuaika" [control]="form.controls.startTime" [required]="true"/>
            <app-datetime-field label="Loppuaika" [control]="form.controls.endTime" [required]="true"/>
            <div></div>
        </app-input-row>

        <h3 class="form-section-title">Reitti</h3>

        <div class="grid grid-cols-2 gap-2 items-start">
            <mat-form-field>
                <mat-label>Reitti</mat-label>
                <app-select-route [formControl]="form.controls.routeId" [allowedRouteIds]="params.details.compatibleRouteIds" required="true"></app-select-route>
            </mat-form-field>
            <app-edit-draft-fields [controls]="form.controls" [vesselDraftBounds]="pilotage.vesselDraftBounds"/>
        </div>

        <app-input-row>
            <app-berth-field
                label="Alkulaituri"
                [control]="form.controls.startBerthId"
                [endpointId]="selectedRoute()?.startId"
                [hint]="(pilotage.route.start.berth && form.controls.startBerthId.value !== pilotage.route.start.berth.id) ? ('Alkuperäinen: ' + pilotage.route.start.berth.code) : null"/>
            <app-berth-field
                label="Loppulaituri"
                [control]="form.controls.endBerthId"
                [endpointId]="selectedRoute()?.endId"
                [hint]="(pilotage.route.end.berth && form.controls.endBerthId.value !== pilotage.route.end.berth.id) ? ('Alkuperäinen: ' + pilotage.route.end.berth.code) : null"/>
        </app-input-row>

        <h3 class="form-section-title">Korjauksen syy</h3>

        <app-textarea-field label="Syy korjaukselle" [control]="form.controls.changeReason"/>

    </app-vertical-form>

</app-common-dialog-form>
