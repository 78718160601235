import { ChangeDetectionStrategy, Component, computed, Inject, Signal } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ChangeOrderTimeParameters, LatePilotBoardingTimeReason, PilotageEndpoint, PilotageId, PilotageScheduleSource } from "apina-frontend";
import { Observable } from "rxjs";
import { arrayOfNotNull, controlValuesSignal, enableWhen, FormType, HelsinkiDatePipe, Instant, MyMatDateTimePickerComponent, PilotageState } from "common";
import { LATE_PILOT_BOARDING_TIME_REASON_SELECTIONS, needsLatePilotBoardingTimeReason } from '../../domain/pilot-boarding-time';
import { LabeledValue } from "../../domain/labeled-value";
import { SelectScheduleSourceComponent, SelectScheduleSourceType } from "../../common/select-schedule-source/select-schedule-source.component";
import { map } from "rxjs/operators";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";

@Component({
    templateUrl: './update-order-time.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SelectScheduleSourceComponent,
        CommonDialogFormComponent,
        MyMatDateTimePickerComponent,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatIconModule,
        HelsinkiDatePipe,
        VerticalFormComponent,
        InputRowComponent,
    ],
})
export class UpdateOrderTimeComponent implements CommonDialogFormDelegate {

    readonly form: FormGroup<UpdateOrderTimeForm>;
    readonly showPilot: boolean;
    readonly pilots: string;
    readonly SelectScheduleSourceType = SelectScheduleSourceType;
    readonly viewData: Signal<UpdateOrderTimeViewData | undefined>;

    constructor(@Inject(MAT_DIALOG_DATA) readonly params: UpdateOrderTimeComponentParams,
                private readonly pilotageEndpoint: PilotageEndpoint) {

        this.pilots = arrayOfNotNull(params.pilotInitials, params.pilot2Initials).join(" + ");

        this.showPilot = params.pilotInitials != null;

        this.form = new FormGroup<UpdateOrderTimeForm>({
            orderTime: new FormControl(params.orderTime),
            pilotBoardingTime: new FormControl(params.pilotBoardingTime ?? params.orderTime),
            source: new FormControl(params.source, {nonNullable: true}),
            latePilotBoardingTimeReason: new FormControl(null, Validators.required),
            transferFee: new FormControl(false, {nonNullable: true}),
            changeLogComment: new FormControl("", {nonNullable: true})
        });

        this.form.controls.orderTime.valueChanges.subscribe(v => {
            if (this.form.controls.pilotBoardingTime.pristine)
                this.form.controls.pilotBoardingTime.reset(v);
        });

        this.viewData = toSignal(pilotageEndpoint.getOrderTimeUpdateData(params.pilotageId).pipe(map(info => {
            this.form.controls.transferFee.reset(info.transferFeeSuggestion.suggestTransferFee);
            if (!info.transferFeeSuggestion.allowTransferFee)
                this.form.controls.transferFee.disable();

            return {
                showPilotOnBoardWarning: params.state === PilotageState.ORDER && info.pilotOnBoard
            };
        })));

        const orderTimeSignal = controlValuesSignal(this.form.controls.orderTime);
        const pilotBoardingTimeSignal = controlValuesSignal(this.form.controls.pilotBoardingTime);
        const needsReason = computed(() => needsLatePilotBoardingTimeReason(params.orderTime, params.pilotBoardingTime, orderTimeSignal(), pilotBoardingTimeSignal(), params.state));

        enableWhen(needsReason, [this.form.controls.latePilotBoardingTimeReason]);
    }

    get latePilotBoardingTimeReasonSelections(): readonly LabeledValue<LatePilotBoardingTimeReason>[] {
        return LATE_PILOT_BOARDING_TIME_REASON_SELECTIONS;
    }

    doSave(): Observable<void> {
        return this.pilotageEndpoint.changeOrderTime(this.params.pilotageId, this.form.value as ChangeOrderTimeParameters);
    }

    isPristine(): boolean {
        return this.form.controls.orderTime.pristine;
    }

    resetPilotEtaToOriginal(event: MouseEvent): void {
        event.preventDefault();

        this.form.controls.pilotBoardingTime.reset(this.params.pilotBoardingTime);
    }
}

interface UpdateOrderTimeForm extends FormType<Omit<ChangeOrderTimeParameters, "orderTime">> {
    orderTime: FormControl<Instant | null>;
}

interface UpdateOrderTimeViewData {
    showPilotOnBoardWarning: boolean;
}

export interface UpdateOrderTimeComponentParams {
    pilotageId: PilotageId;
    state: PilotageState;
    vesselName: string;
    route: string;
    orderTime: Instant | null;
    pilotInitials: string | null;
    pilot2Initials: string | null;
    pilotBoardingTime: Instant | null;
    source: PilotageScheduleSource;
}
