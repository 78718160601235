import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { ApprovablePilotInfo, ApprovePilotageData, PilotageApproveParams, PilotageEndpoint, PilotageId, PilotingMethod, PilotingMethodWithCaptain } from "apina-frontend";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { greaterThanInstant, Hours, Instant, lessThanInstant, Minutes, MyMatDateTimePickerComponent, NauticalMiles } from "common";
import { labeledPilotingMethods, labeledPilotingMethodsWithCaptain, labeledTugCounts } from "../../domain/piloting-methods";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { EditMinutesComponent } from "../../common/edit-minutes/edit-minutes.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { AsyncPipe } from "@angular/common";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";

@Component({
    templateUrl: "./approve-pilotage.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        MatFormFieldModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        CommonDialogFormComponent,
        EditMinutesComponent,
        MyMatDateTimePickerComponent,
        VerticalFormComponent,
        InputRowComponent,
    ],
})
export class ApprovePilotageComponent {

    readonly viewData$: Observable<ApprovePilotageViewData>;

    readonly pilotingMethods = labeledPilotingMethods;
    readonly pilotingMethodsWithCaptain = labeledPilotingMethodsWithCaptain;
    readonly tugCounts = labeledTugCounts;

    constructor(
        @Inject(MAT_DIALOG_DATA) readonly params: ApprovePilotageParams,
        private readonly pilotageEndpoint: PilotageEndpoint
    ) {

        const self = this;
        this.viewData$ = pilotageEndpoint.getApprovePilotageData(params.pilotageId, params.pilot.pilotId).pipe(
            map(pilotage => {
                const askForPilotingMethods = pilotage.askPilotingMethodOnFairway || pilotage.askPilotingMethodOnHarbor || pilotage.askPilotingMethodOnCanalLocks || pilotage.askTugCount;
                const form = createForm(pilotage);
                return ({
                    title: `${pilotage.vesselName}: ${pilotage.routeCodes}`,
                    askForPilotingMethods,
                    form,

                    doSave(): Observable<void> {
                        return self.approve(form.controls, askForPilotingMethods);
                    }
                });
            }),
            shareReplay(1));
    }

    private approve(form: ApproveFormControls, askForPilotingMethods: boolean): Observable<void> {
        const methods = form.method.controls;
        const miles = form.miles.controls;
        const params: PilotageApproveParams = {
            method: askForPilotingMethods ? {
                pilotingMethodOnFairway: methods.pilotingMethodOnFairway.value,
                pilotingMethodOnHarbor: methods.pilotingMethodOnHarbor.value,
                pilotingMethodOnCanalLocks: methods.pilotingMethodOnCanalLocks.value,
                tugCount: methods.tugCount.value
            } : null,
            miles: {
                seaMiles: miles.seaMiles.value,
                saimaaMiles: miles.saimaaMiles.value,
                canalMiles: miles.canalMiles.value,
            },
            pilotingStarted: form.pilotingStarted.value,
            pilotingEnded: form.pilotingEnded.value,
            travelTimeStart: form.travelTimeStart.value,
            travelTimeEnd: form.travelTimeEnd.value,
            preparationTime: form.preparationTime.value!,
            workingTimeMinutes: form.workingTime.value!,
            closingTime: form.closingTime.value!,
            waitedHours: form.waitingHours.value,
        };

        return this.pilotageEndpoint.approvePilotage(this.params.pilotageId, this.params.pilot.pilotId, params);
    }
}

interface ApproveFormControls {
    pilotingStarted: FormControl<Instant | null>;
    pilotingEnded: FormControl<Instant | null>;
    travelTimeStart: FormControl<Instant | null>;
    travelTimeEnd: FormControl<Instant | null>;
    preparationTime: FormControl<Minutes | null>;
    workingTime: FormControl<Minutes | null>;
    closingTime: FormControl<Minutes | null>;
    waitingHours: FormControl<Hours | null>;
    miles: FormGroup<{
        seaMiles: FormControl<NauticalMiles | null>;
        saimaaMiles: FormControl<NauticalMiles | null>;
        canalMiles: FormControl<NauticalMiles | null>
    }>;
    method: FormGroup<{
        pilotingMethodOnHarbor: FormControl<PilotingMethodWithCaptain | null>;
        tugCount: FormControl<number | null>;
        pilotingMethodOnFairway: FormControl<PilotingMethod | null>;
        pilotingMethodOnCanalLocks: FormControl<PilotingMethod | null>
    }>;
}

interface ApprovePilotageViewData extends CommonDialogFormDelegate {
    title: string;
    form: FormGroup<ApproveFormControls>;
    askForPilotingMethods: boolean;
}

export interface ApprovePilotageParams {
    pilotageId: PilotageId;
    pilot: ApprovablePilotInfo;
}

function createForm(pilotage: ApprovePilotageData): FormGroup<ApproveFormControls> {
    const formControls: ApproveFormControls = {
        pilotingStarted: new FormControl(pilotage.startTime, Validators.required),
        pilotingEnded: new FormControl(pilotage.endTime),
        travelTimeStart: new FormControl(pilotage.tripStart),
        travelTimeEnd: new FormControl(pilotage.tripEnd),
        preparationTime: new FormControl(pilotage.preparationTime, Validators.required),
        workingTime: new FormControl(pilotage.workingTime, Validators.required),
        closingTime: new FormControl(pilotage.closingTime, Validators.required),
        waitingHours: new FormControl(pilotage.waitingHours, Validators.required),
        miles: new FormGroup({
            seaMiles: new FormControl(pilotage.miles.seaMiles, Validators.required),
            saimaaMiles: new FormControl(pilotage.miles.saimaaMiles, Validators.required),
            canalMiles: new FormControl(pilotage.miles.canalMiles, Validators.required),
        }),
        method: new FormGroup({
            pilotingMethodOnFairway: new FormControl(pilotage.methods.pilotingMethodOnFairway, Validators.required),
            pilotingMethodOnHarbor: new FormControl(pilotage.methods.pilotingMethodOnHarbor, Validators.required),
            pilotingMethodOnCanalLocks: new FormControl(pilotage.methods.pilotingMethodOnCanalLocks, Validators.required),
            tugCount: new FormControl(pilotage.methods.tugCount, Validators.required),
        })
    };

    formControls.pilotingEnded.addValidators(greaterThanInstant(formControls.pilotingStarted));
    formControls.travelTimeStart.addValidators(lessThanInstant(formControls.pilotingStarted));
    formControls.travelTimeEnd.addValidators(greaterThanInstant(formControls.pilotingEnded));

    formControls.pilotingStarted.valueChanges.subscribe(() => {
        formControls.pilotingEnded.updateValueAndValidity();
        formControls.travelTimeStart.updateValueAndValidity();
        formControls.travelTimeEnd.updateValueAndValidity();
    });

    formControls.pilotingEnded.valueChanges.subscribe(() => {
        formControls.travelTimeStart.updateValueAndValidity();
        formControls.travelTimeEnd.updateValueAndValidity();
    });

    formControls.travelTimeStart.valueChanges.subscribe(() => {
        formControls.travelTimeEnd.updateValueAndValidity();
    });

    if (!pilotage.askWaitingHours)
        formControls.waitingHours.disable();

    if (!pilotage.askPilotingMethodOnFairway)
        formControls.method.controls.pilotingMethodOnFairway.disable();

    if (!pilotage.askPilotingMethodOnHarbor)
        formControls.method.controls.pilotingMethodOnHarbor.disable();

    if (!pilotage.askPilotingMethodOnCanalLocks)
        formControls.method.controls.pilotingMethodOnCanalLocks.disable();

    if (!pilotage.askTugCount)
        formControls.method.controls.tugCount.disable();

    if (!pilotage.askSeaMiles)
        formControls.miles.controls.seaMiles.disable();

    if (!pilotage.askSaimaaMiles)
        formControls.miles.controls.saimaaMiles.disable();

    if (!pilotage.askCanalMiles)
        formControls.miles.controls.canalMiles.disable();

    return new FormGroup(formControls);
}

