import { ChangeDetectionStrategy, Component, Inject, Signal } from "@angular/core";
import { PilotageId, PilotageObservationEndpoint, PilotageObservationForEdit } from "apina-frontend";
import { ErrorService } from "common";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { catchError } from "rxjs/operators";
import { PilotageObservationComponent } from "./pilotage-observation.component";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
    templateUrl: './pilotage-observation-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogModule,
        MatProgressSpinnerModule,
        PilotageObservationComponent,
    ],
})
export class PilotageObservationModalComponent {
    readonly observation: Signal<PilotageObservationForEdit | undefined>;

    constructor(
        @Inject(MAT_DIALOG_DATA) params: PilotageObservationModalComponentParams,
        private readonly dialogRef: MatDialogRef<PilotageObservationModalComponent>,
        endpoint: PilotageObservationEndpoint,
        errorService: ErrorService,
    ) {

        this.observation = toSignal(endpoint.getPilotageObservation(params.pilotageId).pipe(
            catchError(e => {
                errorService.showLoadError(e);
                return [undefined];
            })
        ));
    }

    close(): void {
        this.dialogRef.close();
    }
}

export interface PilotageObservationModalComponentParams {
    pilotageId: PilotageId;
}
