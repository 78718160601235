@if (pilotage(); as p) {
    <app-common-dialog-form
        [delegate]="this"
        dialogTitle="Syötä syväystiedot">

        <app-vertical-form>
            <app-edit-draft-fields [controls]="form.controls" [vesselDraftBounds]="p.vesselDraftBounds"/>
        </app-vertical-form>

    </app-common-dialog-form>
} @else {
    <mat-spinner class="default-spinner"/>
}
