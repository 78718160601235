import { ChangeDetectionStrategy, Component, Inject, Signal } from "@angular/core";
import { PilotageEndpoint, PilotageId, VesselId } from "apina-frontend";
import { Observable } from "rxjs";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { map, tap } from "rxjs/operators";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { HelsinkiDatePipe, Instant, MAX_DRAFT_METERS } from "common";
import { routeDescription } from "../../domain/routes";
import { CommonDialogFormComponent, CommonDialogFormDelegate } from "../../common/common-dialog-form/common-dialog-form.component";
import { SelectVesselComponent } from "../../common/select-vessel/select-vessel.component";
import { createDraftControls, EditDraftFieldsComponent } from "../../common/edit-draft-fields/edit-draft-fields.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { toSignal } from "@angular/core/rxjs-interop";
import { VerticalFormComponent } from "../../forms/vertical-form/vertical-form.component";
import { InputRowComponent } from "../../forms/input-row/input-row.component";
import { VesselsService } from "../../vessels/vessels.service";

@Component({
    templateUrl: "./change-vessel.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonDialogFormComponent,
        SelectVesselComponent,
        EditDraftFieldsComponent,
        HelsinkiDatePipe,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        VerticalFormComponent,
        InputRowComponent,
    ],
})
export class ChangeVesselComponent implements CommonDialogFormDelegate {

    readonly form = new FormGroup({
        vesselId: new FormControl<VesselId | null>(null, Validators.required),
        vessel2Id: new FormControl<VesselId | null>(null),
        ...createDraftControls(),
    });

    readonly viewData: Signal<ChangeVesselViewData | undefined>;
    readonly maxDraft = MAX_DRAFT_METERS;

    constructor(@Inject(MAT_DIALOG_DATA) private readonly params: ChangeVesselComponentParams,
                private readonly vesselsService: VesselsService,
                private readonly pilotageEndpoint: PilotageEndpoint) {

        this.viewData = toSignal(pilotageEndpoint.getPilotageDetails(params.pilotageId).pipe(
            tap(pilotage => this.form.reset({
                vesselId: pilotage.vessel.id,
                vessel2Id: pilotage.vessel2?.id ?? null,
                draft: pilotage.drafts.max,
                draftFore: pilotage.drafts.fore,
                draftAft: pilotage.drafts.aft,
                airDraft: pilotage.drafts.air,
            })),
            map(pilotage => ({
                routeDescription: routeDescription(pilotage.route),
                startTime: pilotage.startTime
            } satisfies ChangeVesselViewData))));
    }

    createNewVessel(): void {
        this.vesselsService.openNewVesselDialog();
    }

    openVesselInfoDialog(e: Event, vesselId: VesselId): void {
        e.preventDefault();
        this.vesselsService.openVesselDialog(vesselId);
    }

    isPristine(): boolean {
        return this.form.controls.vesselId.pristine && this.form.controls.vessel2Id.pristine;
    }

    doSave(): Observable<void> {
        return this.pilotageEndpoint.updateVessel(this.params.pilotageId, {
            vesselId: this.form.controls.vesselId.value!,
            vessel2Id: this.form.controls.vessel2Id.value,
            drafts: {
                max: this.form.controls.draft.value,
                fore: this.form.controls.draftAft.value,
                aft: this.form.controls.draftAft.value,
                air: this.form.controls.airDraft.value,
            }
        });
    }
}

export interface ChangeVesselComponentParams {
    pilotageId: PilotageId;
}

interface ChangeVesselViewData {
    routeDescription: string;
    startTime: Instant;
}
