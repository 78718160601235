<h3 mat-dialog-title>Reitin valinta</h3>

<mat-dialog-content>
    <div class="flex flex-row gap-4 pt-2">
        <mat-form-field appearance="outline">
            <mat-label>Alkupiste</mat-label>
            <input matInput type="text" [formControl]="form.controls.startCode" cdkFocusInitial autofocus>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Päätepiste</mat-label>
            <input matInput type="text" [formControl]="form.controls.endCode">
        </mat-form-field>
    </div>

    @if (searchResults(); as result) {
        <table class="default-table">
            <thead>
            <tr>
                <th>Alku</th>
                <th>Loppu</th>
                <th>Pituus</th>
                <th>Oletus&shy;kesto</th>
                <th>Nimi</th>
            </tr>
            </thead>
            <tbody>
                @for (route of result; track route.id) {
                    <tr (click)="selectRoute(route)" class="hover:cursor-pointer hover:bg-gray-100">
                        <td [matTooltip]="route.start.name">{{ route.start.code }}</td>
                        <td [matTooltip]="route.end.name">{{ route.end.code }}</td>
                        <td>{{ route.miles }} nm</td>
                        <td>{{ route.defaultDuration | minutesDuration }}</td>
                        <td [matTooltip]="route.description ?? ''">{{ route.name }}</td>
                    </tr>
                }
            </tbody>
        </table>
    } @else {
        <mat-spinner class="default-spinner"/>
    }
</mat-dialog-content>
